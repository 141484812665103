import validateEN from 'vee-validate/dist/locale/en.json';
import { mergeWithBrandLangFile } from './helpers';

const original = {
  /**
   * 'common' rules:
   * - Must be generic words.
   * - The key must be written in singular adding the plural into the value.
   * - The key must be written in camelCase.
   * - The value must be written in lower case unless it's a name.
   */
  common: {
    hello: 'hello',
    eur: '€',
    share: 'share | shares',
    dividend: 'dividend | dividends',
    myDividend: 'Your dividend',
    totalValue: 'Total value',
    myInterestRate: 'Your dividend',
    prospectus: 'prospectus',
    investment: 'investment | investments',
    project: 'Real estate | Real estate',
    invest: 'Invest',
    investIn: 'Invest in',
    investMore: 'invest more',
    investNow: 'invest now',
    payment: 'payment | payments',
    name: 'name | names',
    firstName: 'First name',
    surname: 'Last name',
    email: 'e-mail | e-mails',
    e_mail: 'e-mail',
    e_mailAddress: 'E-mail address',
    password: 'password | passwords',
    save: 'save',
    tier: 'tier',
    confirm: 'confirm',
    activate: 'activate',
    eIDAS: 'eIDAS',
    gDPR: 'GDPR',
    iDIN: 'iDIN',
    and: 'and',
    dashboard: 'dashboard',
    myDashboard: 'My dashboard',
    back: 'back',
    sold: 'sold',
    signedUp: 'Signed up',
    participation: 'minimal investment',
    available: 'available',
    download: 'download | downloads',
    brochure: 'brochure',
    identification: 'identification',
    summary: 'summary',
    process: 'process | processes',
    processing: 'processing',
    error: 'error',
    success: 'success',
    paid: 'paid',
    fail: 'fail',
    failed: 'failed',
    canceled: 'canceled',
    cancel: 'cancel',
    expired: 'expired',
    open: 'open',
    step: 'step | steps',
    fullName: 'full name',
    telephone: 'telephone',
    companyName: 'company name',
    phone: 'phone',
    dateOfBirth: 'date of birth',
    bankAccount: 'bank account',
    sendDataChangeRequest: 'Send data change request',
    dataChangeRequestPending: 'Data change request is pending',
    dataChangeRequestValue: 'Requested value',
    iban: 'IBAN',
    city: 'city',
    country: 'country',
    address: 'address',
    houseNumber: 'house number',
    number: 'number',
    postalCode: 'postal code',
    welcome: 'welcome Mr./Mrs.',
    availability: 'availability',
    euro: 'euro | euros',
    euroSymbol: '€',
    contact: 'contact',
    countryOfBirth: 'country of birth',
    additionalInfo: 'additional info',
    or: 'or',
    from: 'from',
    to: 'to',
    for: 'for',
    support: 'support',
    subject: 'subject',
    message: 'message',
    login: 'login',
    loginNow: 'Login now',
    join: 'join now',
    crreateAnAccount: 'Create an account',
    reset: 'reset',
    resetPass: 'reset password',
    setPass: 'set password',
    secondFactor: '2-factor authentication',
    revertSecondFactor: 'revert 2-factor authentication',
    nationality: 'nationality',
    realEstate: 'real estate index',
    aex: 'aex',
    vs: 'vs',
    property: 'property',
    warning: 'warning',
    document: 'document | documents',
    location: 'location | locations',
    floorplan: 'floorplan',
    benefit: 'benefit | benefits',
    company: 'company | companies',
    setting: 'setting | settings',
    settingMenu: 'Account',
    logout: 'logout',
    help: 'help',
    optional: 'optional',
    source: 'source',
    portfolio: 'portfolio',
    projectsLink: 'Real estate | Real estate',
    frequentlyAskedQuestions: 'FAQs',
    news: 'News',
    background: 'Background',
    press: 'Press',
    glossary: 'Glossary',
    cookie: 'cookie | cookies',
    no: 'no',
    yes: 'yes, this is ok',
    loadMore: 'show more Real estate',
    loadLess: 'show less Real estate',
    verified: 'account verified',
    pending: 'account under verification',
    rejected: 'account rejected',
    notVerified: 'account not verified',
    year: 'year | years',
    yearInitials: 'y',
    perYear: 'per year',
    perYearShort: 'p/yr',
    myAccount: 'my account',
    my: 'my',
    details: 'details',
    na: 'n.a.',
    here: 'here',
    send: 'send',
    date: 'date',
    month: 'month | months',
    yearly: 'yearly',
    monthly: 'monthly',
    new: 'new',
    search: 'search',
    next: 'next',
    prev: 'prev',
    fundCompleted: 'completed',
    incorrectPassword: 'incorrect password',
    autoLogOut: 'You have been automatically logged out due to inactivity.',
    investor: 'investor | investors',
    cookiePolicy: 'Cookie policy',
    disclaimer: 'Disclaimer',
    privacyPolicy: 'Privacy policy',
    copyright: 'Pebbles 2020',
    accountDisabled: 'Your account has been disabled.',
    contactSupport: 'Please contact {email} or call {phone} for support.',
    supportQuestion: 'How can we help you? Ask your question here:',
    websiteMaintenance: 'Website under maintenance.',
    closeModal: 'Close',
    paidOnline: 'Paid online',
    paidOffline: 'Paid offline',
    dutch: 'Dutch',
    english: 'English',
    startedIn: 'Started in',
    in: 'in',
    gender: 'gender',
    male: 'male',
    female: 'female',
    emission: 'Emission costs',
    bannerLegal: 'Banner legal',
    investedAmount: 'Invested amount',
    decline: 'Decline',
    accept: 'Accept',
    disabled: 'This option cannot be changed',
  },
  validations: {
    ...validateEN.messages,
    alpha_num: 'This field may only contain alpha-numeric characters',
    alpha_spaces: 'This field may only contain alphabetical characters and spaces',
    confirmed: 'This field confirmation does not match',
    email: 'This field must be a valid email',
    integer: 'This field must be a integer number',
    max: 'This field may not be greater than {length} characters',
    min: 'This field must be at least {length} characters',
    ext: 'File type must be of type {types}',
    required: 'This field is required',
    multiplier: {
      payment: 'The amount to participate has to be a multiplier of the price per share ({number}€).',
    },
  },
  /**
   * Specific section rules:
   * - Never use individual common words (you have the 'common' section for that).
   * - Never use dots.
   * - HTML tags like <a> inside a block of text must split it into two; one, the plain text, and two, the text inside the tag.
   * - No plurals here.
   */
  landing: {
    header: {
      makingRealEstate: 'The people’s real estate',
      investment: 'investment portal',
      applyNowInvestor: 'Register now and become a real estate investor!',
      registerNow: 'Discover the real estate',
      subscribe: 'Send E-mail',
      findOut: 'Click here to find out',
      learnMore: 'Learn more',
      stayInTouch: 'You want to stay updated?',
      stayInTouchSubmitSubject: 'I want to stay in touch!',
      stayInTouchSubmitBody: 'Person with the following email address wants receive updates: <strong>{email}</strong>',
      stayInTouchSubmitSuccess: 'Request to stay in touch successfully submitted!',
    },
    accessible: {
      accessibleAndTransparent: 'Real estate investing for everyone',
      weMakeItVeryEasy: 'We are Pebbles. The first to make real estate investment accessible for everybody. Do you want to know how',
      featuredFund1: 'Featured real estate 1',
      featuredFund2: 'Featured real estate 2',
      featuredFund3: 'Featured real estate 3',
    },
    fund: {
      emissionPrice: 'Price per Share',
      totalFundSize: 'Total real estate size',
      seeDetails: 'See details',
    },
    safeGuards: {
      yourInvestmentsAreProtected: 'Your investments are protected',
      andAreSeparated: 'They are protected and separated from the assets and liabilities of Bloqhouse.',
      compensationScheme: 'Compensation Scheme',
      protectsTheFunds: 'Your bank balance on the portal is insured via a Third-party real estate foundation.',
      financialConductAuthority: 'Regulated',
      dfmaRegulated: `The funds offered on the Pebbles portal are managed by Annexum Beheer, a specialist in private property
      investing with 20 years of experience. Annexum Beheer has an AIFMD license and are supervised by the Authority of Financial Markets (AFM).`,
    },
    steps: {
      howItWorks: 'How does Pebbles work',
      stepOne: 'Create an account and identify yourself online',
      stepTwo: 'Select the real estate you want to invest in',
      stepThree: 'Buy shares via iDEAL',
      stepFour: 'Receive dividend every month',
      stepFive: '',
      pointer: 'Pointer',
    },
    risks: {
      investmentRisks: 'Be aware',
      generalRisksAssociated: `The value of your investment can fall as well as rise.
      You can partly or completely loose the investments you make through Pebbles. Previously achieved results are
      not a reliable indicator of future performance. Gross rent, dividends and capital growth may be lower than estimated.
      A detailed description of the risks per real estate can be found in the accompanying Prospectus.`,
    },
    cookies: {
      allowUs: 'Do you allow us to store {cookiesLink}?',
      yourInfoAnalytics: 'Your information will be used for analytics and website optimisation',
    },
    footer: {
      facebook: 'Facebook',
      twitter: 'Twitter',
      youtube: 'Youtube',
      linkedin: 'Linkedin',
    },
  },
  auth: {
    login: {
      forgotPassword: 'Forgot password?',
      dontHaveAnAccountYet: 'Don\'t have an account yet?',
      accessYourBloqify: 'Access your Bloqify',
      enterYourPassword: 'Enter your password',
      pleaseActivate: 'Please activate your account using the activation link sent to your e-mail',
      userHasRole: 'You tried to log in with an admin account. Please use it in Bloqadmin only.',
      captchaTimeExpired: 'Captcha verification expired',
      tryAgain: 'Please try again',
    },
    register: {
      becomeAnInvestor: 'Become an investor',
      atLeast10Chars: 'At least 10 characters',
      alreadyHaveAnAccount: 'Already have an account?',
      thankYou: 'Thank you for registering',
      pleaseCheckEmail: 'Please check your e-mail to activate your account',
      itCanTake: 'It can take a few minutes to arrive',
      youCan: 'You can',
      afterActivating: 'after activating your account',
      confirmPassword: 'Confirm password',
    },
    reset: {
      getAPassword: 'Get a password reset link',
      sendResetLink: 'Send reset link',
      rememberYourPassword: 'Remember your password?',
      emailSent: 'If the provided email is registered, you should have received an email',
      pleaseCheckInbox: 'Please check your inbox',
      expiredError: 'For security reasons, this link was valid only for 24 hours. Please, insert your e-mail so we can send you an email with a new link to claim your password.',
    },
    verification: {
      emailVerification: 'E-mail verification',
      resetPassword: 'Reset password',
      claimPasswoord: 'Claim password',
      changeEmail: 'Change e-mail',
      verifyingSuccess: 'Easy peasy, right? Now you can start investing',
      verifyingError: 'Something went wrong! Register again',
      changeError: 'Something went wrong! Try again',
      changeSuccess: 'Easy peasy, right? Now you can start investing',
      passwordLenght: 'Password has to be 10 or longer',
      resetPass: 'Reset Password',
      resetPassSuccess: 'Password has been reset',
      changeDataRequestConfirmation: 'Are you sure you want to change your <strong>{type}</strong> from <em>{oldValue}</em> to <em>{newValue}<em/>',
      revertSecondFactor: 'You activated second factor authentication, on this page you can revert this action',
      revertSecondFactorSuccess: 'You successfully deactivated 2-factor authentication',
    },
  },
  dashboard: {
    infobox: {
      upgrade: 'Verify',
      dismiss: 'Dismiss',
      notNow: 'Not now',
      pending: 'Your account is being verified',
      pendingDescription: 'You will be notified soon by {fundManager}',
      youCanInvest: 'You can already invest in your first fund',
      youNeedApproval: 'You can only invest after verifying your account',
      upgradeTo: 'You still need to verify your account',
      basicUser: 'You have now only created an account',
    },
    balance: {
      totalBalance: 'Your shares',
      onTheseFunds: 'In these real estate',
      annualStatement: 'Tax statement',
    },
    value: {
      totalValue: 'Value of the shares',
    },
    dividends: {
      totalDividend: 'Your dividend',
      onTheseFunds: 'From these real estate',
      comingSoon: 'The percentage of interest rate received will be shown here soon',
      totalDividendEarned: 'Total dividend earned',
    },
    funds: {
      onBoard: 'In portfolio',
      notOnBoard: 'Not in portfolio',
      totalValue: 'Total real estate size',
      stillAvailable: 'Still available',
      myInvestment: 'My investment',
      netDividendYieldMine: 'My dividend',
      netDividendYield: 'Dividend',
      prognosedDividend: 'Prognosed dividend',
      startToInvest: 'Start to invest on your first real estate',
      investInOtherFunds: 'Invest on other real estate to earn more interest rate',
      investMoreOnSingleFund: 'Explore other properties',
      showAlltheFunds: 'Show all real estate',
      myPortfolio: 'My Portfolio',
      myProjects: 'My Real estate',
      otherProjects: 'Featured real estate',
      myFundsPlaceholder: 'The real estate and funds in which you have invested are shown here',
      myFundsStartInvest: 'Invest in your first real estate',
      progress: 'Real estate progress',
      more: 'more',
      yourInvestment: 'Your investment',
      premium: 'This is a Premium real estate, please login to see it',
      premiumLabel: 'Premium real estate',
      premiumFund: 'Premium real estate',
      moreDetails: 'More details',
      fullHistory: 'See full history of your investment',
      fullHistoryDividends: 'See full history of your dividends',
    },
    investments: {
      showPayments: 'Show transactions',
      hidePayments: 'Hide transactions',
      loadAllInvestments: 'Load all investments',
      noPayments: 'There are no investments to show yet',
      requestSupport: 'Request support',
      showFewerPayments: 'Show fewer payments',
      showAllPayments: 'Show all payments',
      active: 'active',
      ended: 'ended',
      started: 'started',
    },
    support: {
      technical: 'Technical',
      technicalQuestion: 'Technical question',
      fundSpecificQuestion: 'Real estate specific question',
      aboutTheFund: 'About the real estate',
      sendMessage: 'Send message',
      messageSent: 'Your message has been sent',
      youWillBeAnswered: 'We will answer you by email as soon as possible',
    },
    errors: {
      pdfError: 'There was an error with retrieving the document. Please try again later or contact support',
      noInvestmentsError: 'There are no investments for the selected year',
    },
  },
  settings: {
    details: {
      firstAndLastName: 'First and last name',
      changePassword: 'Change password',
      changeEmail: 'Change e-mail',
      newPasswordOptional: 'New password',
      newPasswordOptionalRepeat: 'Repeat new password',
      confirmWithCurrentPassword: 'Confirm with current password',
      personalDetails: 'Personal details',
      leaveEmpty: 'Leave empty if you do not want to change your password',
      useAtLeastTen: 'Use at least 10 characters to keep your account secure',
      pleaseConfirm: 'In order to change password, please insert the old password first',
      currentPassword: 'Current password',
      passwordToChangeMail: 'Type your current password to change your E-mail address',
      changeHere: 'This is the current IBAN number known to us, change if necessary and click on save.',
      bankAccountNumber: 'Your bank account number',
      mailChanged: 'Your e-mail address has been changed. Please check your e-mail to activate your new e-mail address and login again',
      bankAccountChanged: 'Your bank account was correctly changed',
      passwordChanged: 'Your password has been changed. Please login again',
      nameUpdated: 'Your name has been updated',
      linkSent: 'Please, open the link we just sent to your e-mail in order to confirm the change on your bank account number',
      identity: 'Personal Information',
      credentials: 'Credentials',
    },
    identification: {
      identification: 'Identification',
      complete: 'Verify account',
      support: 'Support',
      title: 'Complete these steps to gain full access',
      userIdentification: 'Create an account',
      registeredToPlatform: 'You have created an account',
      investorIdentification: 'Verify your account',
      completeStep: 'Complete this step and start investing',
      success: 'Your account has been identified',
      rejected: 'Your identification request was rejected',
    },
    tier: {
      currentTier: 'Not applicable',
      cannotRequestTier: 'You cannot request a tier upgrade at the moment',
      workingOnFunctionality: 'We are still working on this functionality',
    },
  },
  howItWorks: {
    howItWorksTitle: 'How Pebbles work?',
    toInvest: 'To invest',
    toInvestText: 'You simply choose the real estate online and how much you want to invest. And you can find all your information in your personal dashboard.',
    createAccount: 'Create an account and buy shares',
    createAccountText: `If you don't have an account yet, create one and identify yourself, for example via iDIN. You can then
    purchase the shares via iDeal or SEPA.`,
    rentalIncome: 'Rental income',
    rentalIncomeText: 'Every quarter you receive a share from the rental income. Your dividend is based on your share in the real estate, minus the costs.',
    informed: 'Informed',
    informedText: `Look at your real estate as often and as much as you want.
    View the value of your share and how much dividend you have already earned. On the road, in bed or at the office.
    Everything online and always available.`,
    toSell: 'To sell',
    toSellText: `Of course you don't have to sell. But if you want to, you can do so via the real Estate Marketplace.
    This makes selling as simple and transparent as buying. You can also wait until the real estate is sold by the real estate manager.
    He strives to sell within 5 to 10 years.`,
    easy: 'Easy as it gets',
    easyText: `It’s true. Investing in real estate has always been complicated, time-consuming and only worthwhile with large amounts.
    Those times are over. We eliminate paperwork, expensive brokers and double costs by using the power of the masses.`,
  },
  help: {
    glossary: {
      sepa: 'SEPA',
      sepaText: `Europe has switched to a joint payment market (SEPA). Everyone in the Netherlands and Europe uses
      the IBAN as an account number. With a SEPA transaction you can transfer money to another Dutch or European account.`,
      dividend: 'Dividend',
      dividendText: 'Dividend is a company\'s profit distribution to its shareholders.',
      projects: 'Real estate',
      projectsText: 'The real estate real estate that you can invest in through Pebbles.',
      prospectus: 'Prospectus',
      prospectusText: 'The document explaining the general risks of investing and the real estate specific risks.',
    },
    faq: {
      faq: 'FAQs',
      realEstateTitle: 'Vastgoed',
      realEstateQuestion1: 'In wat voor soort vastgoed kan ik beleggen?',
      realEstateAnswer1: `Via Pebbles beleg je in woningen, kantoorgebouwen, winkels, bedrijfsruimten
      en hotels. Op de website wordt het vastgoed in subfondsen aangeboden. Je kunt zien welk vastgoed
      er in zo’n subfonds zit.`,
      realEstateQuestion2: 'Kan ik ook in één woning beleggen?',
      realEstateAnswer2: 'Nee, om het risico te spreiden, beleg je via Pebbles altijd in meerdere woningen of ander vastgoed.',
      realEstateQuestion3: 'Waarom heeft Pebbles fondsen met drie woningen?',
      realEstateAnswer3: 'Om het risico te spreiden, beleg je via Pebbles altijd in meerdere objecten.',
      realEstateQuestion4: 'Biedt Pebbles alleen beleggingen in vastgoed aan?',
      realEstateAnswer4: `Het is voor Pebbles ook mogelijk om een fonds aan te bieden dat (gedeeltelijk)
       bestaat uit beleggingen, bijvoorbeeld aandelen in beursgenoteerd vastgoed, effecten en
       investeringen op/in vastgoed zoals zonnecollectoren of laadpalen.`,
      accountTitle: 'Account',
      accountQuestion1: 'Hoe kan ik een account aanmaken?',
      accountAnswer1: `Als je nog geen account hebt, dan klik je op ‘Doe mee” in het hoofdmenu.
      Je vult dan je e-mailadres in en kiest een wachtwoord. Vervolgens ontvang je een e-mail waarin
      je het aanmaken van je account kunt bevestigen via een link. `,
      accountQuestion2: 'Kan ik na het aanmaken van een account gelijk aandelen kopen?',
      accountAnswer2: `Ja, je kan direct overgaan tot het aankopen van de aandelen. Je schrijft je
      hiermee in voor het fonds. Om vervolgens ook de aandelen toegewezen te krijgen na de
      inschrijfperiode van het fonds, is het nodig dat je je identificeert.
      Dat doe je bijvoorbeeld via iDIN, een veilige en vertrouwde inlogmethode van jouw bank.
      Hierna kun je starten met beleggen via Pebbles.`,
      accountQuestion3: 'Waarom moet ik via iDIN inloggen?',
      accountAnswer3: `Volgens de Wet ter voorkoming van witwassen en financieren van terrorisme
      (Wwft) moeten wij onze klanten identificeren. Zo weten we aan wie we onze diensten en producten
      aanbieden. Inloggen via iDIN is een veilige en vertrouwde inlogmethode via jouw bank.`,
      accountQuestion4: 'Ik wil niet met iDIN inloggen, kan dit anders?',
      accountAnswer4: 'Ja, je kunt ook een ID uploaden via het portal. ',
      accountQuestion5: 'Kan ik direct aandelen kopen na het inloggen?',
      accountAnswer5: 'Ja, je kan gelijk aandelen kopen. ',
      accountQuestion6: 'Waar kan ik de waarde van mijn belegging inzien?',
      accountAnswer6: `Op Pebbles heb je je eigen dashboard. In je portfolio kun je zien in welke
      aandelen je belegt, wat de waarde van je aandelen is en welk dividend er aan je wordt uitgekeerd.`,
      accountQuestion7: 'Wanneer krijg ik mijn dividend uitgekeerd?',
      accountAnswer7: `Pebbles houdt maandelijks voor je bij welk dividend je hebt opgebouwd, en keert
      dit 2 keer per jaar aan je uit, namelijk in juni en december.`,
      investTitle: 'Beleggen',
      investQuestion1: 'Hoe werkt het beleggen in woningen bij Pebbles?',
      investAnswer1: `Bij Pebbles beleg je ‘indirect’ in vastgoed. Dat betekent dat wij het beheer
      voor je doen (aankoop van het vastgoed, technisch onderhoud, etc) en dat jij dividend (een deel
      van de winst) krijgt uitgekeerd.`,
      investQuestion2: 'Hoe wordt het dividend bepaald?',
      investAnswer2: `Het dividend bestaat uit direct rendement (de inkomsten uit de huur) en indirect
      rendement (de waardestijging van de woningen). Bij Pebbles krijg je contant rendement uitgekeerd,
      dus in cash. Je kunt ervoor kiezen om dit weer te herbeleggen. Dan gebruik je jouw contante
      dividend om weer nieuwe aandelen te kopen. `,
      investQuestion3: 'Kan ik mijn dividend van Pebbles herbeleggen?',
      investAnswer3: 'Nee, dit kan nog niet.',
      investQuestion4: 'Kan ik in meerdere woningen beleggen?',
      investAnswer4: `De fondsen bij Pebbles bevatten altijd meerdere woningen, maar je kunt er ook voor
      kiezen om in meerdere fondsen, dus meerdere woningen te beleggen.`,
      investQuestion5: 'Kan ik in meerdere fondsen beleggen?',
      investAnswer5: 'Zeker, je kunt in zoveel fondsen beleggen als je zelf wilt.',
      investQuestion6: 'Wat kost het om te beleggen bij Pebbles?',
      investAnswer6: 'Als je aandelen koopt via Pebbles, betaal je 2% emissiekosten over het totale bedrag.',
      sellTitle: 'Verkopen',
      sellQuestion1: 'Kan ik mijn aandelen verkopen wanneer ik wil?',
      sellAnswer1: `In het tweede kwartaal van 2021 zullen we het Captin Trading Platform lanceren
      binnen het Pebbles portal. Je kan daarop jouw aandelen ter verkoop aanbieden, net zoals op de
      beurs. De Pebbles Marktplaats wordt mogelijk gemaakt in samenwerking met Captin, een beursportal
      met licenties van de AFM en DNB. De prijs van jouw aandelen wordt bepaald op basis van vraag en aanbod.`,
      sellQuestion2: 'Hoe kan ik mijn aandelen verkopen?',
      sellAnswer2: `Je kunt jouw aandelen verkopen via het Captin Trading Platform. Voordat je kan
      handelen op de Marktplaats zal je nog een aantal extra stappen moeten doorlopen om een Trader
      Account te krijgen.`,
      sellQuestion3: 'Kost het geld om mijn aandelen te verkopen?',
      sellAnswer3: 'Ja, voor het verhandelen van jouw aandelen via het Captin Trading Platform betaal je 0,5% verkoopkosten en 0,5% aankoopkosten.',
      sellQuestion4: 'Kan ik mijn aandelen verkopen aan een specifiek persoon?',
      sellAnswer4: 'Ja, zie onderstaande vraag. Dit zal buiten het portal om gaan.',
      sellQuestion5: 'Zijn mijn aandelen ook op een andere manier overdraagbaar?',
      sellAnswer5: `Ja, dit is mogelijk. Je dient dan een e-mail te sturen naar Pebbles met daarin het
      aantal aandelen dat je wilt overdragen en de gegevens aan wie je dat wilt doen.
      Dit verzoek moet goedgekeurd worden.`,
      fiscalTitle: 'Fiscaal',
      fiscalQuestion1: 'In welke box valt de belegging bij Pebbles?',
      fiscalAnswer1: `Als je aandelen koopt als ‘natuurlijk persoon’ (dus niet als bedrijf), dan vallen
      jouw beleggingen bij Pebbles in box 3, inkomen uit sparen en beleggen. Beleg je via je bedrijf,
      dan valt dit in box 1, winst uit onderneming/resultaat uit overige werkzaamheden.`,
      fiscalQuestion2: 'Ik beleg met mijn bedrijf, in welke box valt de belegging bij Pebbles dan?',
      fiscalAnswer2: `Als je belegt vanuit jouw bedrijf, dan valt jouw belegging bij Pebbles in box 1,
      winst uit onderneming/resultaat uit overige werkzaamheden.`,
      fiscalQuestion3: 'Krijg ik een jaaroverzicht voor de belastingaangifte?',
      fiscalAnswer3: 'Ja, je kan vanaf Februari 2021 jouw jaaroverzicht downloaden vanuit jouw dashboard.',
    },
  },
  aboutUs: {
    aboutUs: 'About us',
    ourStory: 'Our story',
    ourStoryText: `Investing in real estate usually means that you bring a large bag of money to the table. We have noticed that
    investing is therefore often not feasible for the younger generation. We think that that should be changed and everyone must
    be able to invest in real estate.`,
    ourStoryText2: `Even when you are young. Especially when you are young! With Pebbles we have combined our
    knowledge of real estate, investments and technology and we make it possible for everyone to invest in real estate
    shares from €250.`,
    companiesInvolved: 'Companies involved',
    companiesInvolvedText: `Pebbles is powered by Bloqhouse Technologies, the software portal for fund managers, and private
    real estate investment fund manager, Annexum.`,
    bloqhouseTech: 'Bloqhouse Technologies',
    bloqhouseTechText: `Bloqhouse is market leader in the Netherlands and has been to building software products for the alternative
    investment market since 2016. The team has developed a software portal for fund managers and alternative investment professionals.
     The portal is legally compliant (EU) and includes features like client on-boarding, know-your-customer (KYC) checks, payments,
     reporting, investor management and an optional secondary market. Bloqhouse is on a mission to improve the inclusiveness
     and transparency of financial markets.`,
    annexum: 'Annexum',
    annexumText: `Annexum was founded in 2000 and offers private investors opportunities to invest in real estate. Annexum has been
    structuring, acquiring and managing various real estate investment objects for 20 years. At Annexum investors will find real estate
    that suits your investment preference and your assets. The properties selected by Annexum are located in good locations with
    high-quality tenants. With our personal service and extensive knowledge of the real estate market, we ensure that you get the
    most out of your investments.`,
    ourTeam: 'Our team',
    timRutgers: 'Tim Rutgers',
    timRole: 'Founder - Bloqhouse',
    huibBoissevain: 'Huib Boissevain',
    huibRole: 'Founder - Annexum',
    joostRoosen: 'Joost Roosen',
    joostRole: 'Founder - Captin',
  },
  checkout: {
    fund: {
      selectAnAmount: 'select an amount',
      moreInfo: 'more info',
      emissionPricePerShare: 'Price per Share',
      totalFundSize: 'Total real estate size',
      emissionCosts: 'Emission costs',
      grossDividendYield: 'Gross interest rate yield',
      netDividendYield: 'Interest rate yield',
      verifyYourIdentity: 'verify your identity',
      fundDocs: 'Real estate docs',
      noFilesFound: 'no files found',
    },
    investment: {
      amountIn: 'Select the amount in',
      investmentCosts: 'Investment costs',
      emissionCosts: 'Emission costs',
      totalCosts: 'Total cost',
      maximumInvestment: 'Maximum investment with your current account',
      startThePayment: 'Start payment',
      surpassedMax: `The amount you are trying to buy, added up to what you previously invested in this asset,
        would exceed the limit (€ 100.000).`,
      amountBelowZero: 'Oops. The amount is not a number or smaller than 0.',
      mollieRedirect: 'We guide you to Mollie, our payment friends.',
      selectAnOption: 'select an option',
      dividends: 'select net dividend yield',
      netDividendYield: 'Net dividend yield per year',
      willReceive: 'You will receive',
      acceptAndContinue: 'Accept & Continue',
      availableFund: 'The progress bar shows the availability of the fund',
      legalTerms: 'Legal Terms',
      finalize: 'And finalise the payment',
    },
    legal: {
      accept: 'Accept Legal Terms',
      select: 'Select',
      continue: 'And pay with Mollie',
      legalAgreement: 'Registration form',
      paymentRecap: 'Payment Recap',
      finalisingPayment: 'You are finalising this payment',
      interestRate: 'Interest rate',
    },
    manualOrder: {
      requestForInvestment: 'Request for investment',
      amountToInvestIn: 'Amount to invest in',
      sendRequest: 'Send request',
      youCanAlsoContact: 'You can also contact us via e-mail or phone',
      weWillGetYouBack: 'We\'ll get back to you within a few working days',
    },
    status: {
      paymentCompleted: 'You’re payment is successful',
      paymentFailed: 'Payment failed',
      paymentNotFound: 'Payment not found',
      paymentOpen: 'Payment is being processed',
      youBought: 'You just bought',
      youTriedToBuy: 'You tried to buy <strong>{shares} shares</strong> for <strong>{euro} Euro</strong>',
      tryAgain: 'try again',
      accountInvestAgain: 'More real estate',
      backToThePortfolio: 'Your real estate',
      congratulations: 'Congratulations on another great real estate.',
    },
    unlimited: {
      investMoreThan: 'Invest More Than',
      ourProvider: 'Our payment provider (Mollie) currently can not support payments above',
      toInvestMore: 'To invest more than this amount, please send a manual investment request or make multiple payments',
      startRequest: 'Start request for investment',
      youCanEnter: 'You can enter your details and your investment amount here',
      managerContacts: 'Manager contacts',
    },
    errors: {
      notEnoughPermissions: 'Not enough permissions with this credentials',
      somethingWentWrong: 'Whoops, something went wrong during the identification process',
      somethingIsMissing: 'Something is missing',
      somethingIsNotWorking: 'Something is not working',
      problemPersist: 'If the problem persist, please contact us for technical support',
      error404: 'Error 404 - Page not found',
      backToHomepage: 'Back to homepage',
    },
    redirect: {
      text: 'Generating payment via Mollie, our payment service provider...',
    },
  },
  identification: {
    privateInvestor: 'Private investor',
    selectAnOption: 'Select an option',
    dutchBankAccount: 'Invest as private investor with Dutch bank account',
    noDutchBankAccount: 'Invest as private investor with non-Dutch bank account',
    businessTitle: 'Business investor',
    businessInvestment: 'Invest with your business',
    business: {
      fillFieldsCompany: 'Fill the fields with your company info',
      kvkNumber: 'KVK number',
      uploadYourKvKImage: 'Upload your KvK statement',
      submitYourData: 'Submit your data',
      orContactUs: 'Contact us',
      youCanAlso: 'You can also contact us via e-mail or phone',
      weWillGetBackToYou: 'We\'ll get back to you within a few working days',
    },
    idin: {
      selectAnAmount: 'select an amount',
      identityVerification: 'Identity verification',
      pleaseVerify: 'To make sure you are not a crook, we use',
      verificationService: `This identification service is simple and works to your advantage,
      as long as you have nothing to hide.`,
      completedIdin: 'We\'ve completed your iDIN verification',
      pleaseWait: 'Please wait while we connect to iDIN',
      pleaseSelectAnOption: 'Please, select an option',
      selectBank: 'Select your bank',
      thereWasAnError: 'There was an error with iDIN',
      pleaseContactSupport: 'Please contact support',
      whatIsIdin: 'What is iDIN',
      idinExplanation: `iDIN is a Dutch digital identity provider. Individuals can use iDIN to identify with a company such as {companyName}.
        A person logs in at his or her bank. The bank then passes on the data from the person, who has already been checked by the bank, to {companyName}.
        All customers of the bank that have been fully identified by the bank can use iDIN.`,
      idinError: 'There was an error with iDIN service, please try again or contact support',
    },
    world: {
      fillWithInfo: 'Fill the fields with your info',
      uploadYourPassport: 'Upload your identification document (passport, ID, residence permit, drivers license)',
      submitYourData: 'Submit your data',
    },
    FormStatus: {
      requestSent: 'Request sent!',
      weAreCheckingYourData: 'We are checking your personal information,<br>you will receive an e-mail within a few days with the next steps',
      youAreVerified: 'You are verified and able to invest!',
      exploreProperties: 'Explore properties',
      requestRejected: 'Request rejected!',
      weRejectedYourRequest: 'Your request was rejected, if you think this is an error please contact us',
    },
  },
  properties: {
    exploreProperty: 'More details',
    startDate: 'Started in',
    noResults: 'We could not find any property with this name, please try again!',
    startInvest: 'Starting from',
    startInvestAmount: '€ 250',
  },
  property: {
    propertyInfo: {
      investMore: 'Invest more',
      investOnThisFund: 'Invest in this real estate',
      perYear: 'per year',
      minimalInvestment: 'Minimal investment',
      pricePerShare: 'Price per share',
    },
    propertyContent: {
      progress: 'Registration progress',
      investmentCase: 'Details on the investment case',
      propertyDetails: 'Details on the property',
      additionalInfo: 'Additional info',
    },
  },
  tooltip: {
    amountOwned: 'This is the total number of the Shares you hold',
    amountGet: `Four times per year, your dividend will be automatically transferred to the bank account you provided during the registration.
    If you want to change your bank account, please go to Settings > Details`,
    fundSize: 'Total value of the real estate',
    availability: 'Available amount of shares',
    netDividendYieldMine: 'The total dividend received by you in this sub-fund',
    netDividendYield: 'The percentage you will receive per year',
    yourInvestment: 'The number of shares you own in this sub-fund',
    investmentHistory: 'The history of your investments in this sub-fund',
    dividendHistory: 'The history of your dividend from this sub-fund',
    emissionCosts: 'The fee applied to purchase a share',
    fundProgress: 'Percentage of the real estate that has been sold',
    onlinePayment: 'This payment was made through the portal',
    offlinePayment: 'This payment was made outside of the portal',
    downloadStatement: 'Select the year on the left and click here to download the tax statement you need',
    yourHoldings: 'Your holdings',
    totalValue: 'Total value',
    yourDividend: 'Your dividend',
    initialLogin: 'Click here to see your dashboard and investment overview',
    minimalInvestment: 'Minimal investment',
    pricePerShare: 'Price per share',
  },
  gdpr: {
    bar: {
      title: 'Control the collection and use of your personal data',
      textMain: `With your consent, our partners and we use cookies or equivalents to collect, store and use some of
      your personal data, such as your visit on this website. They are used to analyse audiences, offer personalised content
      and / or targeted advertising campaigns, improve customer knowledge, optimize services and fight fraud.
      Click on the “Accept all” button to consent to these uses or click on “Set your options” to obtain more details
      and / or reject all or part of them.`,
      textWarning: `Warning: By continuing your browsing on this site, you accept these uses. You can change your
      mind at any time.`,
      setOptions: 'Set your options',
      acceptEverything: 'Accept everything',
    },
    modal: {
      headerTitle: 'Our Data Collection Policy',
      headerText: '{companyName} makes use of \'cookies\' on this page. A cookie is a little piece of information stored on your device. By that we are able to recognise you at your next visit.',
      headerTitle2: 'Why we use cookies',
      /* eslint-disable max-len */
      headerText2: 'We use cookies in order to ensure the functionality and security of our website. Further, this allows us to analyse and improve our site, products, and services. By these cookies we recognise you at the next visit. No phone numbers or e-mail addresses are saved in them. You allow us to use these cookies by saving your preferences by clicking \'save\'. \n\n We use different types of cookies\nIn particular four types of cookies: Functional Cookies, Analytics Cookies, Advertisment, and Personalisation cookies. Find below more details and the selection for the different types of cookies we use.',
      /* eslint-enable max-len */
      bodyButtons: 'Select which information services are activated',
      bodyButtonsDeselect: 'Deselect all',
      bodyButtonsSelect: 'Select all',
      bodySeeMore: 'See more',
    },
  },
  /**
   * Meta section
   */
  meta: {
    checkout: {
      title: 'Checkout',
    },
    dashboard: {
      title: 'Portfolio',
    },
    details: {
      title: 'Details',
    },
    faq: {
      title: 'FAQs',
    },
    glossary: {
      title: 'Glossary',
    },
    'about-us': {
      title: 'About us',
    },
    identification: {
      title: 'Identification',
    },
    knowledge: {
      title: 'Knowledge base',
    },
    aboutus: {
      title: 'About us',
    },
    'how-it-works': {
      title: 'How Pebbles works?',
    },
    news: {
      title: 'News',
    },
    landing: {
      description: 'The description of the Landing Page',
    },
    login: {
      title: 'Login',
      description: 'Login to your account to invest in our properties.',
    },
    properties: {
      title: 'Real estate',
      description: 'Browse our properties and find out more about them here.',
    },
    property: {
      description: 'Invest in {name} now! This property is located at {address}. You can start investing from {min} euros.',
    },
    register: {
      title: 'Register',
      description: 'Sign up for {name} and discover our properties. Register with your e-mail to start investing.',
    },
    reset: {
      title: 'Reset password',
    },
    settings: {
      title: 'Settings',
    },
    verification: {
      title: 'Verification',
    },
  },
};

export default {
  en: mergeWithBrandLangFile(original, 'en'),
};
