import validateNL from 'vee-validate/dist/locale/nl.json';
import { mergeWithBrandLangFile } from './helpers';

const original = {
  /**
   * 'common' rules:
   * - Must be generic words.
   * - The key must be written in singular adding the plural into the value.
   * - The key must be written in camelCase.
   * - The value must be written in lower case unless it's a name.
   */
  common: {
    hello: 'hallo',
    eur: '€',
    share: 'certificaat van aandeel | certificaten van aandelen',
    shareShort: 'certificaat | certificaten',
    dividend: 'dividend | dividends',
    myDividend: 'Jouw dividend',
    totalValue: 'Totale waarde',
    myInterestRate: 'Jouw opbrengsten',
    prospectus: 'prospectus',
    investment: 'investering | investeringen',
    project: 'subfonds | subfondsen',
    invest: 'Investeer',
    investIn: 'Investeer in',
    investMore: 'Bijkopen',
    investNow: 'investeer nu',
    payment: 'betaling | betalingen',
    name: 'naam | namen',
    firstName: 'Voornaam',
    surname: 'Achternaam',
    email: 'e-mail | e-mails',
    e_mail: 'e-mail',
    e_mailAddress: 'E-mailadres',
    password: 'wachtwoord | wachtwoorden',
    save: 'opslaan',
    tier: 'tier',
    confirm: 'bevestig',
    activate: 'activeren',
    eIDAS: 'eIDAS',
    gDPR: 'GDPR',
    iDIN: 'iDIN',
    and: 'en',
    dashboard: 'dashboard',
    myDashboard: 'Mijn dashboard',
    back: 'terug',
    sold: 'verkocht',
    signedUp: 'Ingeschreven',
    participation: 'minimale investering',
    available: 'beschikbaar',
    download: 'download | downloads',
    brochure: 'brochure',
    identification: 'identificatie',
    summary: 'samenvatting',
    process: 'start | processen',
    processing: 'processing',
    error: 'error',
    success: 'gelukt',
    paid: 'betaald',
    fail: 'fout',
    failed: 'mislukt',
    canceled: 'geannuleerd',
    cancel: 'annuleren',
    expired: 'verlopen',
    open: 'open',
    step: 'stap | stappen',
    fullName: 'volledige naam',
    telephone: 'telefoon',
    companyName: 'bedrijfsnaam',
    phone: 'telefoon',
    dateOfBirth: 'geboortedatum',
    bankAccount: 'bankrekening',
    sendDataChangeRequest: 'Verstuur data-wijzigings verzoek',
    dataChangeRequestPending: 'Datawijzigingsverzoek is ingediend',
    dataChangeRequestValue: 'Aangevraagde waarde',
    iban: 'IBAN',
    city: 'plaats',
    country: 'land',
    address: 'adres',
    houseNumber: 'huisnummer',
    number: 'nummer',
    postalCode: 'postcode',
    welcome: 'welkom Dhr./Mevr.',
    availability: 'beschikbaarheid',
    euro: 'euro | euros',
    euroSymbol: '€',
    contact: 'contact',
    countryOfBirth: 'land van herkomst',
    additionalInfo: 'overige info',
    or: 'of',
    from: 'vanaf',
    to: 'naar',
    for: 'voor',
    support: 'support',
    subject: 'onderwerp',
    message: 'bericht',
    login: 'Inloggen',
    loginNow: 'Login nu',
    join: 'doe mee',
    createAnAccount: 'Maak account aan',
    reset: 'reset',
    resetPass: 'reset wachtwoord',
    setPass: 'kies uw wachtwoord',
    secondFactor: '2-factor authenticatie',
    revertSecondFactor: '2-factor authenticatie annuleren',
    nationality: 'nationaliteit',
    realEstate: 'vastgoed index',
    aex: 'aex',
    vs: 'vs',
    property: 'property',
    warning: 'waarschuwing',
    document: 'document | documenten',
    location: 'locatie | locaties',
    floorplan: 'plattegrond',
    benefit: 'voordeel | voordelen',
    company: 'bedrijf | bedrijven',
    setting: 'instelling | instellingen',
    settingMenu: 'Account',
    logout: 'log uit',
    help: 'help',
    optional: 'optioneel',
    source: 'bron',
    portfolio: 'portfolio',
    projectsLink: 'vastgoed | vastgoed',
    frequentlyAskedQuestions: 'Veelgestelde vragen',
    news: 'Nieuws',
    background: 'Achtergrond',
    press: 'Pers',
    glossary: 'Woordenlijst',
    cookie: 'cookie | cookies',
    no: 'nee',
    yes: 'ja, dit is oke',
    loadMore: 'meer vastgoed tonen',
    loadLess: 'minder vastgoed tonen',
    verified: 'account geverifieerd',
    pending: 'account wordt geverifieerd',
    rejected: 'account niet geaccepteerd',
    notIdentified: 'account niet gevonden',
    year: 'jaar | jaren',
    yearInitials: 'j',
    perYear: 'per jaar',
    perYearShort: 'p/jr',
    myAccount: 'mijn account',
    my: 'mijn',
    details: 'gegevens',
    na: 'n.v.t.',
    here: 'hier',
    send: 'verstuur',
    bond: 'obligatie | obligaties',
    month: 'maand | maanden',
    date: 'datum',
    yearly: 'jaarlijks',
    monthly: 'maandelijks',
    new: 'nieuwe',
    search: 'zoeken',
    next: 'volgende',
    prev: 'vorige',
    fundCompleted: 'volgeschreven',
    incorrectPassword: 'verkeerd wachtwoord',
    autoLogOut: 'U bent automatisch uitgelogd als gevolg van langdurige inactiviteit',
    investor: 'investeerder | investeerders',
    cookiePolicy: 'Cookie policy',
    disclaimer: 'Disclaimer',
    privacyPolicy: 'Privacyverklaring',
    copyright: 'Pebbles 2020',
    accountDisabled: 'Uw account is gedeactiveerd.',
    contactSupport: 'Neem contact op met {nameEmail} voor ondersteuning.',
    supportQuestion: 'Waar kunnen we je mee helpen? Stel hier je vraag:',
    websiteMaintenance: 'Website gesloten voor onderhoud.',
    closeModal: 'Sluit venster',
    paidOnline: 'Online betaling',
    paidOffline: 'Offline betaling',
    dutch: 'Nederlands',
    english: 'Engels',
    startedIn: 'Gestart op',
    in: 'in',
    gender: 'aanhef',
    male: 'meneer',
    female: 'mevrouw',
    emission: 'Emissiekosten',
    bannerLegal: 'Banner legal',
    investedAmount: 'Geïnvesteerd bedrag',
    decline: 'Weigeren',
    accept: 'Accepteer',
    disabled: 'Deze selectie kan niet worden gewijzigd',
    exploreProperties: 'Bekijk het vastgoed',
  },
  validations: {
    ...validateNL.messages,
    alpha_num: 'Dit veld mag alleen letters en nummers bevatten',
    alpha_spaces: 'Dit veld mag alleen letters en spaties bevatten',
    confirmed: 'Dit veld komt niet overeen met eerder gegeven input',
    email: 'Dit veld moet een geldig e-mailadres bevatten',
    integer: 'Dit veld moet moet een nummer zijn',
    max: 'Dit veld mag niet groter zijn dan {length} karakters',
    min: 'Dit veld moet minimaal {length} karakters zijn',
    ext: 'Bestand moet van het type {types} zijn',
    required: 'Dit veld is verplicht',
    multiplier: {
      payment: 'Het bedrag van de inleg moet een meervoud zijn van {number}€',
    },
  },
  /**
   * Specific section rules:
   * - Never use individual common words (you have the 'common' section for that).
   * - Never use dots.
   * - HTML tags like <a> inside a block of text must split it into two; one, the plain text, and two, the text inside the tag.
   * - No plurals here.
   */
  landing: {
    header: {
      makingRealEstate: 'Het vastgoed',
      investment: 'investeringsportaal <br> voor iedereen',
      applyNowInvestor: 'Meld je aan en start direct met beleggen',
      registerNow: 'Ontdek het vastgoed',
      subscribe: 'E-mail verzenden',
      findOut: 'Lees hier hoe het werkt',
      learnMore: 'Leer meer',
      stayInTouch: 'Wil je op de hoogte blijven?',
      stayInTouchSubmitSubject: 'Ik wil op de hoogte blijven!',
      stayInTouchSubmitBody: 'Iemand met het volgende e-mailadres wil op de hoogte blijven: <strong>{email}</strong>',
      stayInTouchSubmitSuccess: 'Aanvraag om op de hoogte te blijven succesvol verzonden!',
    },
    accessible: {
      accessibleAndTransparent: 'Investeren in vastgoed voor iedereen',
      weMakeItVeryEasy: 'Wij zijn Pebbles. De eerste die investeren in vastgoed voor iedereen toegankelijk maakt. Wil je weten hoe',
      featuredFund1: 'Aanbevolen Vastgoed 1',
      featuredFund2: 'Aanbevolen Vastgoed 2',
      featuredFund3: 'Aanbevolen Vastgoed 3',
    },
    fund: {
      emissionPrice: 'Prijs per aandeel',
      totalFundSize: 'Totale vastgoed grootte',
      seeDetails: 'Meer info',
    },
    safeGuards: {
      yourInvestmentsAreProtected: 'Beheerd met ervaring',
      andAreSeparated: `De woningfondsen die Pebbles aanbiedt worden beheerd door Annexum, die met 20 jaar ervaring uitgebreide
      kennis van de vastgoedmarkt heeft. Annexum biedt vastgoedbeleggingen voor particulieren en heeft een AFM vergunning voor
      het beheer van beleggingsinstellingen.`,
      compensationScheme: 'Controle en bewaking',
      protectsTheFunds: `Volgens de AIFM richtlijn moet een bewaarder worden aangesteld voor de fondsen om de processen te bewaken
      en controleren of het beleid wordt nageleefd. De bewaarder van de Pebbles-fondsen is IQ-EQ Depositary.`,
      financialConductAuthority: 'Transparant',
      dfmaRegulated: 'Via het portaal van Pebbles zijn de prestaties van de fondsen altijd zichtbaar en transparant.',
    },
    steps: {
      howItWorks: 'Hoe werkt Pebbles',
      pointer: 'Pointer',
    },
    risks: {
      investmentRisks: 'Essentiële Informatie',
      // eslint-disable-next-line max-len
      generalRisksAssociated: 'Het is belangrijk om zelf te bepalen of de op dit portaal aangeboden beleggingen bij je passen. Daarvoor is het raadzaam om de kansen en risico’s zorgvuldig af te wegen. Beleggen kent risico’s, zoals bijvoorbeeld marktrisico, rendementsrisico, verhandelbaarheidsrisico en leegstandsrisico. De waarde van jouw belegging kan zowel dalen als stijgen. De investeringen die je doet via Pebbles kan je deels of volledig kwijt raken. Eerder behaalde resultaten vormen geen betrouwbare indicator voor toekomstige prestaties. Brutohuur, dividenden en kapitaalgroei kunnen lager zijn dan geschat. Een uitgebreide beschrijving van de risico’s per subfonds is te vinden in het bijbehorende prospectus.',
    },
    cookies: {
      allowUs: 'Staat u ons toe om {cookiesLink} op te slaan?',
      yourInfoAnalytics: 'Uw informatie zal worden gebruikt voor analyse en website-optimalisatie',
    },
    footer: {
      facebook: 'Facebook',
      twitter: 'Twitter',
      youtube: 'Youtube',
      linkedin: 'Linkedin',
      instagram: 'Instagram',
    },
  },
  auth: {
    login: {
      forgotPassword: 'Wachtwoord vergeten?',
      dontHaveAnAccountYet: 'Nog geen account?',
      accessYourBloqify: 'Bloqify dashboard',
      enterYourPassword: 'Vul uw wachtwoord in',
      pleaseActivate: 'We hebben je een e-mailbericht gestuurd met daarin een link om jouw account te activeren',
      userHasRole: 'U heeft geprobeerd in te loggen met een admin account. Gelieve alleen te gebruiken binnen de Bloqadmin omgeving.',
      captchaTimeExpired: 'Captcha verificatie mislukt',
      tryAgain: 'Probeer nogmaals',
    },
    register: {
      becomeAnInvestor: '',
      atLeast10Chars: 'Minimaal 10 tekens',
      alreadyHaveAnAccount: 'Al een account?',
      thankYou: 'Bedankt voor je registratie',
      pleaseCheckEmail: 'Wij hebben een e-mailbericht naar je verzonden om je account te activeren',
      itCanTake: 'Dit kan een paar minuten duren',
      caution: 'Als de e-mail niet in jouw inbox zit, check ook even de spamfolder',
      youCan: '',
      afterActivating: 'na activatie van je account',
      confirmPassword: 'Bevestig wachtwoord',
    },
    reset: {
      getAPassword: 'Stuur mij een wachtwoord reset link',
      sendResetLink: 'Stuur reset link',
      rememberYourPassword: 'Wachtwoord onthouden?',
      emailSent: 'Als het ingevoerde e-mailadres bij ons bekend is, heeft u een e-mail ontvangen',
      pleaseCheckInbox: 'Klik op de link in de e-mail om je wachtwoord te resetten',
      expiredError: 'Uit veiligheidsoverwegingen was deze link maar 24 uur geldig. Vul hier uw e-mailadres in om een nieuwe e-mail te versturen, volg die link en maak het wachtwoord aan.',
    },
    verification: {
      emailVerification: 'E-mail verificatie',
      resetPassword: 'Reset wachtwoord',
      claimPasswoord: 'Claim uw wachtwoord',
      changeEmail: 'Verandering e-mail',
      verifyingSuccess: 'Je kunt nu weer verder met investeren in vastgoed',
      verifyingError: 'Er is iets fout gegaan! Registreer opnieuw',
      changeError: 'Er is iets fout gegaan! Probeer het opnieuw',
      changeSuccess: 'Je kunt nu weer verder met investeren in vastgoed',
      passwordLenght: 'Wachtwoord moet 10 of langer zijn',
      resetPass: 'Wachtwoord opnieuw instellen',
      resetPassSuccess: 'Wachtwoord is opnieuw ingesteld',
      changeDataRequestConfirmation: 'Weet je zeker dat je je <strong>{type}</strong> van <em>{oldValue}</em> naar <em>{newValue}</em> wilt veranderen',
      revertSecondFactor: 'U heeft 2-factor authenticatie geactiveerd, op deze pagina kunt u dit ongedaan maken',
      revertSecondFactorSuccess: 'U heeft 2-factor authenticatie succesvol gedeactiveerd',
    },
  },
  dashboard: {
    infobox: {
      upgrade: 'Verifieer account',
      dismiss: 'Begrepen',
      notNow: 'Niet nu',
      pending: 'Jouw account wordt geverifieerd',
      pendingDescription: 'U krijgt spoedig bericht van {fundManager}',
      youCanInvest: 'Je kan wel alvast investeren in je eerste fonds',
      youNeedApproval: 'Pas na verificatie van jouw account kan je beleggen',
      upgradeTo: 'Je moet jouw persoonlijke gegevens nog laten verifiëren',
      basicUser: 'Als je wilt investeren in vastgoed, dan moeten wij jouw gegevens online verifiëren',
    },
    balance: {
      totalBalance: 'Mijn vastgoedcertificaat | Mijn vastgoedcertificaten',
      onTheseFunds: 'Geïnvesteerd in',
      annualStatement: 'Jaaroverzicht',
    },
    value: {
      totalValue: 'Waarde van mijn certificaten',
    },
    dividends: {
      totalDividend: 'Mijn dividend',
      onTheseFunds: 'Ontvangen uit',
      comingSoon: 'Binnenkort worden hier uw ontvangen rentes getoond',
      totalDividendEarned: 'Totaal aan dividend ontvangen',
    },
    funds: {
      onBoard: 'In portfolio',
      notOnBoard: 'Niet in portfolio',
      totalValue: 'Omvang fonds',
      stillAvailable: 'Geïnvesteerd bedrag',
      myInvestment: 'Mijn vastgoedcertificaten',
      myInvestmentAmount: 'Waarde van mijn certificaten',
      netDividendYieldMine: 'Mijn dividend',
      netDividendYield: 'Streefrendement',
      prognosedDividend: 'Streefrendement',
      startToInvest: 'Start met beleggen in vastgoed',
      investInOtherFunds: 'Investeer ook in rest van de vastgoed om meer dividend te ontvangen',
      investMoreOnSingleFund: 'Bekijk het vastgoed aanbod',
      showAlltheFunds: 'Toon alle vastgoed',
      myPortfolio: 'Mijn Portfolio',
      myProjects: 'Mijn fonds',
      otherProjects: 'Aanbevolen vastgoed',
      myFundsPlaceholder: 'Hier worden de vastgoed en fondsen getoond waarin je hebt geïnvesteerd',
      myFundsStartInvest: 'Investeer in je eerst project',
      progress: 'Geïnvesteerd bedrag',
      more: 'meer',
      yourInvestment: 'Uw investering',
      premium: 'Dit is een Besloten project, login om deze sectie te zien',
      premiumLabel: 'Besloten vastgoed',
      premiumFund: 'Besloten fonds',
      moreDetails: 'Meer details',
      fullHistory: 'Bekijk de volledige historie van jouw investeringen',
      fullHistoryDividends: 'Bekijk de volledige historie van jouw dividend',
    },
    investments: {
      showPayments: 'Toon transacties',
      hidePayments: 'Verberg transacties',
      loadAllInvestments: 'Alle investeringen',
      noPayments: 'Er zijn nog geen transacties',
      requestSupport: 'Neem contact met ons op',
      showFewerPayments: 'Minder transacties',
      showAllPayments: 'Meer transacties',
      active: 'actief',
      ended: 'uitgestapt',
      started: 'begonnen',
    },
    support: {
      technical: 'Technisch',
      technicalQuestion: 'Technische vraag',
      fundSpecificQuestion: 'project specifieke vraag',
      aboutTheFund: 'Over het project',
      sendMessage: 'Stuur bericht',
      messageSent: 'Je bericht is verzonden',
      youWillBeAnswered: 'Wij zullen je z.s.m. per e-mailbericht beantwoorden',
    },
    errors: {
      pdfError: 'Er is een fout opgetreden bij het ophalen van het document. Probeer het later opnieuw of neem contact op met ondersteuning',
      noInvestmentsError: 'Er zijn geen investeringen voor het geselecteerde jaar',
    },
  },
  settings: {
    details: {
      firstAndLastName: 'Voor en achternaam',
      changePassword: 'Verander wachtwoord',
      changeEmail: 'Verander e-mailadres',
      newPasswordOptional: 'Nieuw wachtwoord',
      newPasswordOptionalRepeat: 'Bevestig nieuw wachtwoord',
      confirmWithCurrentPassword: 'Bevestig met huidig wachtwoord',
      personalDetails: 'Persoonlijke gegevens',
      leaveEmpty: 'Laat leeg als je je wachtwoord niet wilt veranderen',
      useAtLeastTen: 'Gebruik ten minste 10 tekens om je account veilig te houden',
      pleaseConfirm: 'Voer eerst het oude wachtwoord in om het wachtwoord te wijzigen',
      currentPassword: 'Huidig wachtwoord',
      passwordToChangeMail: 'Typ uw huidige wachtwoord om uw e-mailadres te wijzigen',
      bankAccountNumber: 'Uw bankrekening nummer',
      changeHere: 'Dit is het huidige bij ons bekende IBAN nummer, wijzig indien nodig en klik op opslaan.',
      mailChanged: 'Uw e-mailadres is gewijzigd. Controleer uw e-mail om het nieuwe e-mailadres te activeren en opnieuw in te loggen',
      bankAccountChanged: 'Jouw IBAN is succesvol aangepast',
      passwordChanged: 'Je wachtwoord is gewijzigd. Log alsjeblieft nogmaals in',
      nameUpdated: 'Uw naam is bijgewerkt',
      linkSent: 'Open de link die we zojuist naar uw e-mail hebben gestuurd om uw wijziging van bankrekeningnummer te bevestigen',
      identity: 'Persoonlijke Informatie',
      identityInformation: 'Dit is het huidige bij ons bekende naam en achternaam, verstuur hieronder het verzoek om deze gegevens te wijzigen.',
      bankAccountInformation: 'Dit is het huidige bij ons bekende IBAN nummer, verstuur hieronder het verzoek om deze gegevens te wijzigen.',
      credentials: 'Geloofsbrieven',
    },
    identification: {
      identification: 'Identificatie',
      complete: 'Verifieer account',
      support: 'Support',
      title: 'Voltooi deze stappen om volledig toegang te krijgen',
      userIdentification: 'Account aanmaken',
      registeredToPlatform: 'Je hebt een account aangemaakt',
      investorIdentification: 'Verifieer jouw persoonlijke gegevens',
      completeStep: 'Voltooi deze stap en begin met investeren',
      success: 'Jouw account is geverifieerd',
      rejected: 'Jouw identificatieverzoek is afgewezen',
    },
    tier: {
      currentTier: 'Niet van toepassing',
      cannotRequestTier: 'Je kan op dit moment niet jouw tier level inzien of wijzigen',
      workingOnFunctionality: 'We werken hard aan deze functionaliteit',
    },
  },
  howItWorks: {
    howItWorksTitle: 'Hoe werkt Pebbles?',
    stepOne: 'Account aanmaken en jezelf identificeren',
    stepOneText: 'Heb je nog geen account? In een paar stappen maak je ‘m eenvoudig aan. Identificeer je makkelijk en veilig via je vertrouwde inlogmethode van je bank. En je bent startklaar.',
    stepTwo: 'Selecteer het vastgoed waarin je wilt investeren',
    stepTwoText: 'Selecteer het vastgoed naar keuze en bepaal hoeveel je wilt investeren.',
    stepThree: 'Vastgoedcertificaten aankopen via SEPA of iDEAL',
    stepThreeText: 'Na het accepteren van de investeringsvoorwaarden kun je je certificaten aankopen via een SEPA-transactie of een iDEAL-transactie.',
    stepFour: 'Dividend uit de huurinkomsten ontvangen',
    stepFourText: `Iedere maand berekenen wij jouw 'deel' van de huurinkomsten; jouw dividend.
    De hoogte van dit dividend wordt bepaald op basis van het aantal certificaten dat je hebt.
    Wij keren het 2 keer per jaar aan je uit.`,
    stepFive: 'Vastgoedcertificaten verkopen',
    stepFiveText:
      `Je hoeft natuurlijk niet te verkopen. Pebbles verwacht op korte termijn ook de mogelijkheid te lanceren om de certificaten via het portaal op een digitale marktplaats te kunnen verhandelen.
      Dit maakt verkopen net zo simpel en transparant als kopen.`,
    extraSectionOne: 'Overzicht van je beleggingen',
    extraSectionOneText: `Je hebt bij ons altijd een actueel online overzicht van de waarde van je certificaten en hoeveel dividend je al hebt verdiend.
    Je ziet je vermogen opbouwen. Er zal in de toekomst ook een Pebbles mobiele app gelanceerd worden.`,
    extraSectionTwo: 'Makkelijker kunnen we het niet maken',
    extraSectionTwoText: `Ja, dat is een knipoog. Maar het is wel waar. Investeren in vastgoed was altijd ingewikkeld, tijdrovend en pas lonend bij grote bedragen.
    Die tijden zijn voorbij. Wij elimineren papierwerk, dure makelaars en dubbele kosten door gebruik te maken van de kracht van de massa.`,
  },
  help: {
    glossary: {
      introduction: 'Hieronder vind je een uitleg over de woorden en termen<br />die gebruikt worden op het Pebbles portaal.',
      word1: 'AFM',
      explanation1:
        `De Autoriteit Financiële Markten (AFM) is de Nederlandse gedragstoezichthouder op de financiële markten.
        Dat houdt in dat zij erop toezien dat partijen correct handelen en hun partners van de juiste informatie voorzien.`,
      word2: 'AFM vergunning',
      explanation2:
        'Financiële ondernemingen moeten over een vergunning van de AFM beschikken om activiteiten te mogen verrichten in Nederland.',
      word3: 'AIFM richtlijn',
      explanation3:
        'De AIFM-richtlijn schrijft voor dat een beheerder niet zonder vergunning een alternatieve beleggingsinstelling mag beheren en/of deelnemingsrechten daarin mag aanbieden aan beleggers.',
      word4: 'Beheerder / fondsbeheerder',
      explanation4:
        'De rechtspersoon die het beheer voert over het fonds en die verantwoordelijk is voor het administratieve, financiële, technische en commerciële beheer.',
      word5: 'Beleggingsfonds',
      explanation5:
        'In een beleggingsfonds wordt het geld van individuele beleggers samengebracht. Dit verzamelde geld wordt belegd in bijvoorbeeld vastgoed.',
      word6: 'Bewaarder',
      explanation6:
        'Dit is een onafhankelijke externe partij die belast is met de controle op het beheer van de beleggingsinstelling en die de belangen van de deelnemers beschermt.',
      word7: 'Certificaten van aandelen',
      explanation7:
        `Dit is een certificaat dat verbonden is met een aandeel.
        Het volgt het aandeel in de koers en levert een gelijke opbrengst op als het onderliggende aandeel.
        Met een certificaat van aandelen heb je geen stemrecht zoals bij aandelen.`,
      word8: 'Dividend',
      explanation8:
        'De uitkering van een bedrijf aan haar aandeelhouders. Deze uitkering kan contant worden uitgekeerd of in nieuwe certificaten van aandelen.',
      word9: 'Emissiekosten',
      explanation9:
        'Eenmalige kosten die worden doorberekend voor het plaatsen van de emissie.',
      word10: 'Geïnvesteerd bedrag',
      explanation10:
        'Het totale bedrag dat geïnvesteerd is in een fonds.',
      word11: 'iDEAL',
      explanation11:
        'Met iDEAL betaal je via je eigen mobiel bankieren app of de internetbankieromgeving van jouw eigen bank.',
      word12: 'iDIN',
      explanation12:
        'Met iDIN kunnen consumenten zich bij andere organisaties online identificeren, met de veilige en vertrouwde inlogmiddelen van hun eigen bank.',
      word13: 'Pebbles Paraplufonds BV',
      explanation13:
        'De officiële naam waaronder Pebbles aan beleggers de mogelijkheid biedt om deel te nemen in de subfondsen.',
      word14: 'Prospectus',
      explanation14:
        'Een prospectus is een wettelijk vereist juridisch document bij een emissie van beleggingsproducten en waarin de voorwaarden van de emissie vermeld worden.',
      word15: 'Prospectus vrijstelling',
      explanation15:
        `Binnen de bestaande regelgeving geldt een prospectusvrijstelling voor emissies tot € 5 miljoen.
        De vrijstelling houdt in dat de AFM het prospectus niet hoeft te beoordelen.
        Wel is er een fondsmelding bij de AFM nodig.`,
      word16: 'Rendement',
      explanation16:
        'Dit is de opbrengst van jouw investering. Deze opbrengst wordt meestal uitgedrukt in een percentage van het startvermogen.',
      word17: 'SEPA',
      explanation17:
        'Dit staat voor Single Euro Payment Area. Door het invoeren van onder andere IBAN is er geen verschil meer tussen binnenlandse en buitenlandsebetalingen.',
      word18: 'Streefrendement',
      explanation18:
        'Dit is het totaalrendement per jaar waarnaar wordt gestreefd.',
      word19: 'Subfonds',
      explanation19:
        'Pebbles biedt beleggers de mogelijkheid om deel te nemen aan fondsen die op de website vermeld staan, de subfondsen.',
    },
    faq: {
      faq: 'FAQs',
      introduction: 'Hieronder vind je direct een antwoord op de meest gestelde vragen<br /> over investeren in vastgoed via Pebbles.',
      realEstateTitle: 'Vastgoed',
      realEstateQuestion1: 'In wat voor soort vastgoed kan ik beleggen?',
      realEstateAnswer1: `Via Pebbles beleg je in woningen, kantoorgebouwen, winkels, bedrijfsruimten
      en hotels. Op de website wordt het vastgoed in subfondsen aangeboden. Je kunt zien welk vastgoed
      er in zo’n subfonds zit.`,
      realEstateQuestion2: 'Kan ik ook in één woning beleggen?',
      realEstateAnswer2: 'Nee, om het risico te spreiden, beleg je via Pebbles altijd in meerdere woningen of ander vastgoed.',
      realEstateQuestion3: 'Waarom heeft Pebbles fondsen met drie woningen?',
      realEstateAnswer3: 'Om het risico te spreiden, beleg je via Pebbles altijd in meerdere objecten.',
      realEstateQuestion4: 'Biedt Pebbles alleen beleggingen in vastgoed aan?',
      realEstateAnswer4: `Het is voor Pebbles ook mogelijk om een fonds aan te bieden dat (gedeeltelijk)
       bestaat uit beleggingen, bijvoorbeeld certificaten van aandelen in beursgenoteerd vastgoed, effecten en
       investeringen op/in vastgoed zoals zonnecollectoren of laadpalen.`,
      accountTitle: 'Account',
      accountQuestion1: 'Hoe kan ik een account aanmaken?',
      accountAnswer1: `Als je nog geen account hebt, dan klik je op ‘Doe mee” in het hoofdmenu.
      Je vult dan je e-mailadres in en kiest een wachtwoord. Vervolgens ontvang je een e-mail waarin
      je het aanmaken van je account kunt bevestigen via een link. `,
      accountQuestion2: 'Kan ik na het aanmaken van een account gelijk certificaten kopen?',
      accountAnswer2: `Ja, je kan direct overgaan tot het aankopen van de certificaten van aandelen. Je schrijft je
      hiermee in voor het fonds. Om vervolgens ook de certificaten toegewezen te krijgen na de
      inschrijfperiode van het fonds, is het nodig dat je je identificeert.
      Dat doe je bijvoorbeeld via iDIN, een veilige en vertrouwde inlogmethode van jouw bank.
      Hierna kun je starten met beleggen via Pebbles.`,
      accountQuestion3: 'Waarom moet ik via iDIN inloggen?',
      accountAnswer3: `Volgens de Wet ter voorkoming van witwassen en financieren van terrorisme
      (Wwft) moeten wij onze klanten identificeren. Zo weten we aan wie we onze diensten en producten
      aanbieden. Inloggen via iDIN is een veilige en vertrouwde inlogmethode via jouw bank.`,
      accountQuestion4: 'Ik wil niet met iDIN inloggen, kan dit anders?',
      accountAnswer4: 'Ja, je kunt ook een ID uploaden via het portaal. ',
      accountQuestion5: 'Kan ik direct certificaten kopen na het inloggen?',
      accountAnswer5: 'Ja, je kan gelijk certificaten kopen. ',
      accountQuestion6: 'Waar kan ik de waarde van mijn belegging inzien?',
      accountAnswer6: `Op Pebbles heb je je eigen dashboard. In je portfolio kun je zien in welke
      certificaten van aandelen je belegt, wat de waarde van je certificaten zijn en welk dividend er aan je wordt uitgekeerd.`,
      accountQuestion7: 'Wanneer krijg ik mijn dividend uitgekeerd?',
      accountAnswer7: `Pebbles houdt maandelijks voor je bij welk dividend je hebt opgebouwd, en keert
      dit 2 keer per jaar aan je uit, namelijk in juni en december.`,
      investTitle: 'Beleggen',
      investQuestion1: 'Hoe werkt het beleggen in woningen bij Pebbles?',
      investAnswer1: `Bij Pebbles beleg je ‘indirect’ in vastgoed. Dat betekent dat wij het beheer
      voor je doen (aankoop van het vastgoed, technisch onderhoud, etc) en dat jij dividend (een deel
      van de winst) krijgt uitgekeerd.`,
      investQuestion2: 'Hoe wordt het dividend bepaald?',
      investAnswer2: `Het dividend bestaat uit direct rendement (de inkomsten uit de huur) en indirect
      rendement (de waardestijging van de woningen). Bij Pebbles krijg je contant rendement uitgekeerd,
      dus in cash. Je kunt ervoor kiezen om dit weer te herbeleggen. Dan gebruik je jouw contante
      dividend om weer nieuwe certificaten van aandelen te kopen.`,
      investQuestion3: 'Kan ik mijn dividend van Pebbles herbeleggen?',
      investAnswer3: 'Nee, dit kan nog niet.',
      investQuestion4: 'Kan ik in meerdere woningen beleggen?',
      investAnswer4: `De fondsen bij Pebbles bevatten altijd meerdere woningen, maar je kunt er ook voor
      kiezen om in meerdere fondsen, dus meerdere woningen te beleggen.`,
      investQuestion5: 'Kan ik in meerdere fondsen beleggen?',
      investAnswer5: 'Zeker, je kunt in zoveel fondsen beleggen als je zelf wilt.',
      investQuestion6: 'Wat kost het om te beleggen bij Pebbles?',
      investAnswer6: 'Als je certificaten koopt via Pebbles, betaal je 2% emissiekosten over het totale bedrag.',
      sellTitle: 'Verkopen',
      sellQuestion1: 'Kan ik mijn certificaten verkopen wanneer ik wil?',
      sellAnswer1: `In het tweede kwartaal van 2021 zijn we voornemens een handelsplatform lanceren binnen het Pebbles portaal.
      Je kan daarop jouw certificaten van aandelen ter verkoop aanbieden, net zoals op de beurs. De marktplaats wordt mogelijk
      gemaakt in samenwerking een beursplatform met licenties van de AFM en DNB. De prijs van jouw certificaten wordt bepaald op
      basis van vraag en aanbod.`,
      sellQuestion2: 'Hoe kan ik mijn certificaten verkopen?',
      sellAnswer2: `Je kunt jouw certificaten van aandelen verkopen via het handelsplatform. Voordat je kan handelen op de
      Marktplaats zal je nog een aantal extra stappen moeten doorlopen om een Trader Account te krijgen.`,
      sellQuestion3: 'Kost het geld om mijn certificaten te verkopen?',
      sellAnswer3: 'Ja, voor het verhandelen van jouw certificaten van aandelen via het handelsplatform betaal je 0,5% verkoopkosten en 0,5% aankoopkosten.',
      sellQuestion4: 'Kan ik mijn certificaten verkopen aan een specifiek persoon?',
      sellAnswer4: `Ja, dat kan. Je dient dan een e-mail te sturen naar Pebbles met daarin het aantal certificaten van aandelen dat je wilt
      overdragen en de gegevens aan wie je dat wilt doen. Dit verzoek moet goedgekeurd worden. Dit gaat dan overigens buiten
      het portaal om.`,
      fiscalTitle: 'Fiscaal',
      fiscalQuestion1: 'In welke box valt de belegging bij Pebbles?',
      fiscalAnswer1: `Als je certificaten koopt als ‘natuurlijk persoon’ (dus niet als bedrijf), dan vallen
      jouw beleggingen bij Pebbles in box 3, inkomen uit sparen en beleggen. Beleg je via je bedrijf,
      dan valt dit in box 1, winst uit onderneming/resultaat uit overige werkzaamheden.`,
      fiscalQuestion2: 'Ik beleg met mijn bedrijf, in welke box valt de belegging bij Pebbles dan?',
      fiscalAnswer2: `Als je belegt vanuit jouw bedrijf, dan valt jouw belegging bij Pebbles in box 1,
      winst uit onderneming/resultaat uit overige werkzaamheden.`,
      fiscalQuestion3: 'Krijg ik een jaaroverzicht voor de belastingaangifte?',
      fiscalAnswer3: 'Ja, je kan vanaf Februari 2021 jouw jaaroverzicht downloaden vanuit jouw dashboard.',
    },
  },
  aboutUs: {
    aboutUs: 'Over ons',
    ourStory: 'Pebbles. Ons verhaal.',
    ourStoryText: `Beleggen in vastgoed betekent eigenlijk dat je over een flinke zak geld moet beschikken. We zien daarom dat dit voor
    de jongere generatie vaak niet haalbaar is. Dat kan anders vinden wij. Iedereen moet kunnen beleggen in vastgoed.`,
    ourStoryText2: `Ook als je jong bent. Juist als je jong bent! In Pebbles hebben wij onze kennis van vastgoed, beleggingen en
    techniek gecombineerd en maken we het voor iedereen mogelijk om vanaf €250 in vastgoed te beleggen.`,
    companiesInvolved: 'Betrokken bedrijven',
    companiesInvolvedText: `Pebbles wordt mogelijk gemaakt door Bloqhouse Technologies, het software portaal voor fondsbeheerders,
    en aanbieder van vastgoedbeleggingsfondsen voor particulieren, Annexum.`,
    bloqhouseTech: 'Bloqhouse Technologies',
    bloqhouseTechText: `Bloqhouse is marktleider in Nederland en bouwt sinds 2016 softwareproducten voor de alternatieve
    beleggingsmarkt. Het team heeft een softwareportaal ontwikkeld voor fondsmanagers. Het portaal is gebouwd in lijn met wet en
    regelgeving en bevat functionaliteiten zoals de onboarding van klanten, ken-uw-klant (KYC) controles, betalingen, rapportage en
    beleggersbeheer. Het is de missie van Bloqhouse om de inclusiviteit en de transparantie van de financiële markten te verbeteren.`,
    annexum: 'Annexum',
    annexumText: `Annexum bestaat sinds 2000 en biedt de particuliere belegger mogelijkheden om te beleggen in vastgoed.
    Annexum structureert, acquireert en beheert diverse vastgoedbeleggingsobjecten, al 20 jaar.
    De door Annexum geselecteerde vastgoedobjecten bevinden zich op goede locaties met kwalitatief goede huurders.
    Met haar persoonlijke dienstverlening en uitgebreide kennis van de vastgoedmarkt zorgt Annexum dat beleggers het optimale uit hun beleggingen kunnen halen.`,
    ourTeam: 'Het team',
    timRutgers: 'Tim Rutgers',
    timRole: 'Founder - Bloqhouse',
    huibBoissevain: 'Huib Boissevain',
    huibRole: 'Founder - Annexum',
    joostRoosen: 'Joost Roosen',
    joostRole: 'Founder - Captin',
  },
  checkout: {
    fund: {
      selectAnAmount: 'investeringsbedrag',
      moreInfo: 'meer info',
      emissionPricePerShare: 'Prijs per aandeel',
      totalFundSize: 'Omvang fonds',
      emissionCosts: 'Emissie kosten',
      grossDividendYield: 'Bruto rente',
      netDividendYield: 'Streef',
      verifyYourIdentity: 'identificeer mij',
      fundDocs: 'documentatie',
      noFilesFound: 'geen docs gevonden',
    },
    investment: {
      amountIn: 'Selecteer het bedrag in',
      investmentCosts: 'Investering',
      emissionCosts: 'Emissiekosten',
      totalCosts: 'Totale investering',
      maximumInvestment: 'Maximale investering met jouw account',
      startThePayment: 'Start de betaling',
      mollieRedirect: 'We begeleiden je even naar Mollie, onze vrienden van de betaling.',
      dividends: 'selecteer netto rendement',
      netDividendYield: 'Netto rendement',
      willReceive: 'Je ontvangt',
      placeOrder: 'plaats order',
      legalTerms: 'Kies investeringsbedrag',
      finalize: 'Ga door naar juridische voorwaarden',
      selectAnOption: 'selecteer een optie',
      availableFund: 'De voortgangsbalk geeft de beschikbaarheid van het fonds weer',
      acceptAndContinue: 'Akkoord & Doorgaan',
    },
    legal: {
      accept: 'Accepteer de voorwaarden',
      select: 'Selecteren',
      continue: 'Ga door naar betaling',
      legalAgreement: 'Investeringsvoorwaarden',
      paymentRecap: 'Betalingsoverzicht',
      finalisingPayment: 'Voltooi de betaling',
      interestRate: 'Rente',
    },
    manualOrder: {
      requestForInvestment: 'Vraag investering aan',
      amountToInvestIn: 'Bedrag ter investering',
      sendRequest: 'Stuur aanvraag',
      youCanAlsoContact: 'Je kan ook contact opnemen met ons via e-mail of telefoon',
      weWillGetYouBack: 'Je wordt beantwoord binnen 48 uur',
    },
    status: {
      paymentCompleted: 'De inschrijving is succesvol, het geld is overgemaakt',
      paymentFailed: 'Betaling mislukt',
      paymentNotFound: 'Betaling niet gevonden',
      paymentOpen: 'Betaling wordt verwerkt',
      youBought: 'Je kocht',
      youTriedToBuy: 'Je probeerderde <strong>{shares} certificaten van aandelen</strong> te kopen voor <strong>{euro} Euro</strong>',
      tryAgain: 'probeer nogmaals',
      accountInvestAgain: 'Meer vastgoed',
      backToThePortfolio: 'Jouw dashboard',
      congratulations: 'Gefeliciteerd met jouw deelname in <strong>{assetName}</strong>',
    },
    unlimited: {
      investMoreThan: 'Investeer meer dan',
      ourProvider: 'Onze payment provider kan momenteeel je transactie niet verwerken',
      toInvestMore: 'Om meer te investeren, stuur ons een aanvraag of betaal in meerdere stappen',
      startRequest: 'Start investerings aanvraag',
      youCanEnter: 'Verstrek ons je gegevens en het gewenste bedrag ter investering',
      managerContacts: 'Beheer contacten',
    },
    errors: {
      notEnoughPermissions: 'Onvoldoende rechten met deze referenties',
      somethingWentWrong: 'Oeps, er is iets misgegaan tijdens het identificatieproces',
      somethingIsMissing: 'Er mist iets',
      somethingIsNotWorking: 'Er gaat iets mis',
      problemPersist: 'Als het probleem blijft bestaan, neem dan contact met ons op voor technische ondersteuning',
      error404: 'Fout 404 - Pagina niet gevonden',
      backToHomepage: 'Terug naar de startpagina',
    },
    redirect: {
      text: 'De betaling wordt gegenereerd via Mollie, onze betaaldienstverlener...',
    },
  },
  identification: {
    privateInvestor: 'Particuliere investeerder',
    selectAnOption: 'Kies hoe je wilt gaan investeren',
    dutchBankAccount: 'Ik wil mijn gegevens opsturen via iDIN',
    noDutchBankAccount: 'Ik wil mijn identiteitsbewijs uploaden',
    businessTitle: 'Zakelijke investeerder',
    businessInvestment: 'Ik wil mijn identiteitsbewijs en bedrijfsgegevens uploaden',
    business: {
      fillFieldsCompany: 'Identificatie middels identiteitsbewijs en bedrijfsgegevens',
      kvkNumber: 'KvK nummer',
      uploadYourKvKImage: 'Upload je KvK uittreksel',
      submitYourData: 'Verzend gegevens',
      orContactUs: 'Contact ons',
      youCanAlso: 'Je kan ook contact opnemen met ons via e-mail of telefoon',
      weWillGetBackToYou: 'Je wordt beantwoord binnen 48 uur',
    },
    idin: {
      selectAnAmount: 'selecteer een bedrag',
      identityVerification: 'Identificatie via iDIN',
      pleaseVerify: 'Om er zeker van te zijn dat jij dit echt bent, maken we gebruik van',
      verificationService: 'Deze identificatie werkt via de inlog bij je bank (nee, je hoeft nog niets te betalen) en is simpel en snel',
      completedIdin: 'Je iDIN identificatie is voltooid',
      pleaseWait: 'Een momentje, we zoeken contact met iDIN',
      pleaseSelectAnOption: 'Selecteer een optie',
      selectBank: 'Selecteer je bank',
      thereWasAnError: 'Er is iets misgegaan met iDIN',
      pleaseContactSupport: 'Contact onze helpdesk alsjeblieft',
      whatIsIdin: 'Wat is iDIN',
      idinExplanation: `iDIN is een Nederlands online identificatiemiddel. Particulieren kunnen zich met behulp van iDIN identificeren bij een bedrijf zoals {companyName}.
        De persoon logt in bij zijn of haar bank die dan bepaalde gegevens van de persoon, die de bank zelf al gecontroleerd heeft, doorgeeft aan het bedrijf.
        Alle klanten van de bank die al volledig door de bank zijn geïdentificeerd kunnen gebruik maken van iDIN.`,
      idinError: 'Er is een fout opgetreden met de iDIN-service. Probeer het opnieuw of neem contact op met de ondersteuning',
    },
    world: {
      fillWithInfo: 'Identificatie middels identiteitsbewijs',
      uploadYourPassport: 'Upload je identiteitsbewijs (paspoort, identiteitskaart of rijbewijs)',
      submitYourData: 'Verzend gegevens',
    },
    FormStatus: {
      requestSent: 'Aanvraag verzonden!',
      weAreCheckingYourData: 'We controleren je gegevens,<br>we zullen binnen een uur jouw account verifiëren',
      youAreVerified: 'Je bent geverifieerd en klaar om te investeren!',
      exploreProperties: 'Bekijk het vastgoed',
      requestRejected: 'Verzoek afgewezen!',
      weRejectedYourRequest: 'U bent niet als belegger geaccepteerd. <br> Als u denkt dat dit een fout is, neemt u contact met ons op',
    },
  },
  properties: {
    exploreProperty: 'Meer details',
    startDate: 'Gestart op',
    noResults: 'Op dit moment zijn we op zoek naar geschikte proposities voor op Pebbles, stay tuned! ',
    startInvest: 'Inleggen vanaf',
    startInvestAmount: '€ 250',
  },
  property: {
    propertyInfo: {
      investMore: 'Investeer meer',
      investOnThisFund: 'Investeer in dit fonds',
      perYear: 'per jaar',
      minimalInvestment: 'Minimale investering',
      pricePerShare: 'Prijs per aandeel',
    },
    propertyContent: {
      progress: 'Voortgang inschrijving',
      investmentCase: 'Details over het fonds',
      propertyDetails: 'Details over het vastgoed',
      additionalInfo: 'Extra informatie',
    },
  },
  tooltip: {
    amountOwned: 'Het totaal aantal certificaten van aandelen dat u in bezit heeft',
    amountGet: `Vier keer per jaar wordt uw dividend automatisch overgemaakt naar de bankrekening die u tijdens de registratie hebt gebruikt.
    Als u uw bankrekening wilt wijzigen, gaat u naar Instellingen> Details`,
    fundSize: 'Totale waarde van het project',
    availability: 'Beschikbaar aantal certificaten van aandelen',
    netDividendYieldMine: 'Pebbles houdt maandelijks voor je bij welk dividend je hebt opgebouwd, enkeert dit 2 keer per jaar aan je uit, namelijk in juni en december',
    netDividendYield: 'Het jaarlijkse rendement dat verwacht wordt',
    yourInvestment: 'Het aantal certificaten van aandelen dat je bezit in dit subfonds',
    investmentHistory: 'De historie van jouw investeringen in dit subfonds',
    dividendHistory: 'De historie van jouw dividend uit dit subfonds',
    emissionCosts: 'De vergoeding die wordt gerekend om een ​​aandeel te kopen',
    fundProgress: 'Percentage van het fonds dat is volschreven',
    onlinePayment: 'Deze betaling is via het portaal uitgevoerd',
    offlinePayment: 'Deze betaling is buiten het portaal uitgevoerd',
    downloadStatement: 'Selecteer het jaar aan de linkerkant en klik om het jaaroverzicht naar keuze te downloaden',
    yourHoldings: 'Mijn vastgoedcertificaten',
    totalValue: 'Waarde van mijn certificaten van aandelen',
    yourDividend: 'Mijn dividend',
    initialLogin: 'Klik hier om je dashboard en je investeringsoverzicht te zien',
    minimalInvestment: 'Minimale investering',
    pricePerShare: 'Prijs per aandeel',
    downloads: 'De op deze pagina elektronisch ter beschikking gestelde documenten zijn tevens in hard copy beschikbaar. Op verzoek zullen deze documenten kosteloos worden toegezonden.',
  },
  gdpr: {
    bar: {
      title: 'Control the collection and use of your personal data',
      textMain: `With your consent, our partners and we use cookies or equivalents to collect, store and use some of
      your personal data, such as your visit on this website. They are used to analyse audiences, offer personalised content
      and / or targeted advertising campaigns, improve customer knowledge, optimize services and fight fraud.
      Click on the “Accept all” button to consent to these uses or click on “Set your options” to obtain more details
      and / or reject all or part of them.`,
      textWarning: `Warning: By continuing your browsing on this site, you accept these uses. You can change your
      mind at any time.`,
      setOptions: 'Set your options',
      acceptEverything: 'Accept everything',
    },
    modal: {
      headerTitle: 'Onze Data Collection Policy',
      /* eslint-disable max-len */
      headerText: '{companyName} maakt op deze site gebruik van cookies. Een cookie is een klein stukje informatie dat in de vorm van een bestand op uw computer of mobiele apparaat wordt opgeslagen. Hierdoor herkennen wij uw computer of mobiele apparaat bij een volgend bezoek.',
      headerTitle2: 'Waarom wij cookies plaatsen',
      headerText2: 'Wij plaatsen cookies om onze site goed te laten werken en te beveiligen. En om onze site, producten en diensten te analyseren en te verbeteren. Door cookies herkennen we u bij een volgend bezoek. Telefoonnummers en e-mailadressen worden niet opgeslagen in cookies. U geeft toestemming voor cookies door op Accepteer te klikken.\n\nWij plaatsen verschillende soorten cookies\nWe kennen vier soorten cookies: Functionele Cookies, Analytische Cookies, Advertentie en personalisatiecookies. We leggen u graag uit op welke manier wij deze verschillende soorten cookies gebruiken.',
      bodyButtons: 'Selecteer of deselecteer welke gegevens u met ons wilt delen',
      bodyButtonsDeselect: 'Alles deselecteren',
      bodyButtonsSelect: 'Alles selecteren',
      bodySeeMore: 'Meer info',
      /* eslint-enable max-len */
    },
  },
  cta: {
    message1: 'Bekijk het vastgoed om te investeren.',
    message2: 'Nieuwsgierig naar waarin je kunt investeren? Bekijk ons vastgoed.',
    message3: 'Wil je investeren? Dit zijn de vastgoedfondsen waaraan je kunt deelnemen.',
  },
  /**
   * Meta section
   */
  meta: {
    checkout: {
      title: 'Uitchecken',
    },
    dashboard: {
      title: 'Portfolio',
    },
    details: {
      title: 'Gegevens',
    },
    faq: {
      title: 'FAQs',
    },
    glossary: {
      title: 'Woordenlijst',
    },
    'about-us': {
      title: 'Over ons',
    },
    identification: {
      title: 'Identificatie',
    },
    knowledge: {
      title: 'Kennisbank',
    },
    aboutus: {
      title: 'Over ons',
    },
    'how-it-works': {
      title: 'Hoe werkt Pebbles?',
    },
    news: {
      title: 'Nieuws',
    },
    landing: {
      description: 'De beschrijving van de Landing Page',
    },
    login: {
      title: 'Login',
      description: 'Log in op uw account om te investeren in onze vastgoed.',
    },
    properties: {
      title: 'Vastgoed',
      description: 'Blader door onze vastgoed en ontdek hier meer over hen.',
    },
    property: {
      description: 'Investeer nu in {name}. Deze woning bevindt zich op {address}. U kunt beginnen met Inleggen vanaf {min} euros.',
    },
    register: {
      title: 'Registreer',
      description: 'Meld je aan voor {name} en ontdek onze vastgoed. Registreer met uw e-mail om te beginnen met beleggen.',
    },
    reset: {
      title: 'Reset wachtwoord',
    },
    settings: {
      title: 'Account',
    },
    verification: {
      title: 'Wachtwoord is opnieuw ingesteld',
    },
  },
};

export const disclaimer: { title: string, text: string }[] = [
  /* eslint-disable max-len */
  {
    title: 'Toepasselijkheid',
    text: 'Deze gebruiksvoorwaarden (ook wel: “Disclaimer”) zijn van toepassing op het bezoek aan en gebruik van deze website van Pebbles (“Website”) alsmede op de op of via de Website aan u ter beschikking gestelde informatie, aanbevelingen, prospectussen en/of tdiensten (“Informatie”). Door het gebruik van de Website gaat u akkoord met de toepasselijkheid daarop van de Disclaimer. Wij wijzen u erop dat deze voorwaarden van tijd tijd kunnen worden gewijzigd. Bezoekers van de Website wordt geadviseerd periodiek de voorwaarden op wijzigingen na te gaan.\nDe Disclaimer ligt ter inzage ten kantore van Pebbles en zal op verzoek via info@pebbles.eu of telefoonnummer 020 – 572 0120 kosteloos worden toegezonden. Tevens kunt u de Disclaimer downloaden onder “Downloads” op deze website, en vervolgens desgewenst uitprinten.',
  },
  {
    title: 'Informatie en aansprakelijkheid',
    text: 'De Informatie is uitsluitend bedoeld voor algemene informatiedoeleinden en is niet bedoeld als advies. Pebbles is niet aansprakelijk voor enige schade ten gevolge van het gebruik (of de onmogelijkheid te gebruiken) van de website, daaronder begrepen schade ten gevolge van virussen of de onjuistheid of onvolledigheid van de Informatie, tenzij de schade het gevolg is van opzet of grove schuld van Pebbles. Pebbles is verder niet aansprakelijk voor schade ten gevolge van het gebruik van elektronische middelen voor communicatie met deze website, daaronder begrepen – maar niet beperkt tot – schade ten gevolge van niet aflevering of vertraging bij de aflevering van elektronische berichten, onderschepping of manipulatie van elektronische berichten door derden of door programmatuur/apparatuur gebruikt voor elektronische communicatie en overbrenging van virussen.\nEen online op de website ter beschikking gestelde prospectus kan afwijken van de hard-copy versie van de prospectus met betrekking tot hetzelfde beleggingsobject of hetzelfde fonds (het kan bijvoorbeeld een verkorte versie zijn). Indien u naar aanleiding van de online prospectus interesse heeft voor een bepaald beleggingsobject of fonds, dient u de hard-copy versie van de prospectus aan te vragen. U kunt de hard-copy versie van de gewenste prospectus telefonisch op 020 – 572 0120 of per e-mail aan info@pebbles.eu aanvragen. Uw eventuele aanvrage voor participatie in een beleggingsobject of beleggingsfonds wordt geacht te geschieden op basis van de hard-copy versie van de prospectus waarin u het inschrijfformulier aantreft.',
  },
  {
    title: 'Links met andere websites',
    text: 'De Website bevat links naar externe internetpagina’s. Pebbles is niet aansprakelijk voor het gebruik of de inhoud van internetpagina’s waarnaar op deze website een link is opgenomen of van internetpagina’s waarop een link is opgenomen naar deze Website.',
  },
  {
    title: 'Cookies',
    text: 'Pebbles maakt gebruik van cookies. Dit zijn kleine bestanden die op uw eigen computer uw surf gedrag opslaan. Dit heeft verschillende functies; zo kan Pebbles.eu o.a. bijhouden hoeveel bezoekers de site op een dag heeft en welke websites verder populair zijn onder onze bezoekers. Cookies onthouden alleen uw voorkeuren en interesses op basis van surfgedrag. Ze onthouden dus geen persoonlijke gegevens. Door gebruik te maken van deze website verleent de gebruiker toestemming voor het gebruik van cookies. Pebbles hanteert een strikt privacy beleid, eveneens met betrekking tot cookies. Uw informatie wordt met de grootst mogelijke zorgvuldigheid behandeld en beveiligd.',
  },
  {
    title: 'Eigendomsrechten',
    text: 'Tenzij anders aangegeven, berusten alle rechten, waaronder auteursrechten en andere intellectuele eigendomsrechten, op de website en de Informatie bij Pebbles.\nBezoekers mogen de website en de Informatie raadplegen en daarvan kopieën maken voor eigen gebruik, bijvoorbeeld door deze te printen of op te slaan. Elk ander gebruik van de Website of van de Informatie, bijvoorbeeld het opslaan of reproduceren van (een deel van) de Website in een andere internetpagina of het aanbrengen van koppelingen, hyperlinks of deeplinks tussen de Website en enige andere internetpagina, is niet toegestaan zonder de uitdrukkelijke schriftelijke toestemming van Pebbles.',
  },
  {
    title: 'Ongevraagd ingestuurde ideeën',
    text: 'In geval u ongevraagd ideeën en/of materialen, waaronder begrepen, maar niet beperkt tot, teksten, beelden, geluiden, software of informatie (de “Materialen”) op deze Website plaatst of deze toestuurt aan Pebbles via e-mail of anderszins, zal Pebbles gerechtigd zijn deze materialen te gebruiken, te kopiëren en/of commercieel te exploiteren, een en ander in de ruimste zin, zonder daarvoor een vergoeding verschuldigd te zijn en Pebbles zal niet gehouden zijn de betreffende Materialen geheim te houden. U vrijwaart hierbij Pebbles terzake van alle schade en kosten die Pebbles lijdt of maakt terzake van aanspraken van derden dat het gebruik en/of de exploitatie van de Materialen inbreuk maakt op (intellectuele) eigendomsrechten van derden of anderszins onrechtmatig is jegens een derde.',
  },
  {
    title: 'Nietigheid',
    text: 'Is of worden deze gebruiksvoorwaarden gedeeltelijk ongeldig, dan blijven partijen aan het overblijvende gedeelte gebonden. Partijen zullen het ongeldige gedeelte vervangen door bedingen die wel geldig zijn en waarvan de rechtsgevolgen, gelet op de inhoud en de strekking van deze gebruiksvoorwaarden, zoveel mogelijk overeenstemmen met die van het ongeldige gedeelte.',
  },
  {
    title: 'Toepasselijk recht en bevoegde rechter',
    text: 'Deze gebruiksvoorwaarden zijn uitsluitend onderworpen aan Nederlands recht. Alle geschillen die in verband met deze gebruiksvoorwaarden ontstaan, geschillen over het bestaan en de geldigheid daarvan daaronder begrepen, zullen worden beslecht door de bevoegde rechter te Amsterdam.',
  },
];

export default {
  nl: mergeWithBrandLangFile(original, 'nl'),
};
