export default {
  en: {
    identification: {
      idin: {
        idinExplanation: `iDIN is a Dutch digital identity provider. Individuals can use iDIN to identify with a company such as Pebbles.
        A person logs in at his or her bank. The bank then passes on the data from the person, who has already been checked by the bank, to Pebbles.
        All customers of the bank that have been fully identified by the bank can use iDIN.`,
      },
    },
  },
  nl: {
    identification: {
      idin: {
        idinExplanation: `iDIN is een Nederlands online identificatiemiddel. Particulieren kunnen zich met behulp van iDIN identificeren bij een bedrijf zoals Pebbles.
        De persoon logt in bij zijn of haar bank die dan bepaalde gegevens van de persoon, die de bank zelf al gecontroleerd heeft, doorgeeft aan het bedrijf.
        Alle klanten van de bank die al volledig door de bank zijn geïdentificeerd kunnen gebruik maken van iDIN.`,
      },
    },
  },
};
